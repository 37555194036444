import React, { useEffect, useContext } from 'react'
import styled from 'styled-components'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'

import { parseQueryString } from '../utils/generic'

import preloader from '../images/preloader.gif'
import {
  PaymentStatus,
  PaymentProviders,
  PaymentTypes,
} from '../utils/constants'
import { AuthContext } from '../context/authProvider'
import toast from '../components/common/toast'

import Layout from '../components/common/layout'

const Wrapper = styled.div`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  overflow: hidden;
  background: url('${preloader}') no-repeat center;
  background-size: 100px;
`

const PaymentSuccess = () => {
  const intl = useIntl()
  const location = useLocation()
  const { getUserWallet } = useContext(AuthContext)
  const params = parseQueryString(location.search)

  useEffect(() => {
    window.parent.postMessage(JSON.stringify(params), '*')

    if (
      params.status === PaymentStatus.SUCCESS &&
      params.accountId &&
      params.amount &&
      params.currency
    ) {
      if (typeof window !== 'undefined') {
        window.dataLayer.push({
          event: 'DepositConfirm',
          accountId: params.accountId,
          stake: params.amount,
          currency: params.currency,
        })
      }
    }

    if (
      params.status === PaymentStatus.SUCCESS &&
      params.provider !== undefined
    ) {
      switch (params.provider) {
        case PaymentProviders.PIX:
          setTimeout(
            (_) =>
              toast.success(
                intl.formatMessage({ id: 'payments.deposit.successPix' })
              ),
            1000
          )
          break
        case PaymentProviders.GIGADAT_INTERAC_CPI:
          setTimeout(
            (_) =>
              toast.success(
                intl.formatMessage({ id: 'payments.deposit.successGigadat' })
              ),
            1000
          )
          break
        default:
          setTimeout(
            (_) =>
              toast.success(
                intl.formatMessage({ id: 'payments.deposit.success' })
              ),
            1000
          )
      }

      getUserWallet()

      setTimeout((_) => navigate(`/${intl.locale}/sports/`), 5000)
    } else if (params.status === PaymentStatus.ERROR) {
      toast.error(intl.formatMessage({ id: 'payments.receipt.error' }))

      if (params.type) {
        setTimeout((_) => {
          params.type.toLowerCase() === PaymentTypes.DEPOSIT
            ? navigate(`/${intl.locale}/deposit/`)
            : navigate(`/${intl.locale}/withdraw/`)
        }, 3000)
      }
    } else {
      navigate(`/${intl.locale}/deposit/`)
    }
  }, [getUserWallet, intl, params])

  return (
    <Layout>
      <Wrapper></Wrapper>
    </Layout>
  )
}

export default PaymentSuccess
